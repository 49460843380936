<template>
  <div>
    <div class="d-flex">
      <h3 class="mb-0 ml-0 color4 font-weight-bolder">
        {{ clientData.id == 0 ? "ADD" : "EDIT" }} CLIENT Account
      </h3>
    </div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="mt-2 mt-sm-3">
            <b-col cols="12" sm="8" lg="6">
              <validation-provider
                #default="validationContext"
                name="First Name"
                rules="required"
              >
                <b-form-group
                  label="First Name"
                  label-for="first_name"
                  label-cols-sm="3"
                  class="required"
                >
                  <b-form-input
                    id="first_name"
                    v-model="clientData.first_name"
                    :state="getValidationState(validationContext)"
                    placeholder="Enter first name"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" sm="8" lg="6">
              <validation-provider
                #default="validationContext"
                name="Last Name"
                rules="required"
              >
                <b-form-group
                  label="Last Name"
                  label-for="last_name"
                  label-cols-sm="3"
                  class="required"
                >
                  <b-form-input
                    id="last_name"
                    v-model="clientData.last_name"
                    :state="getValidationState(validationContext)"
                    placeholder="Enter last name"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" sm="8" lg="6">
              <validation-provider
                #default="validationContext"
                name="Email"
                rules="required"
              >
                <b-form-group
                  label="Email"
                  label-for="email"
                  label-cols-sm="3"
                  class="required"
                >
                  <b-form-input
                    id="email"
                    v-model="clientData.email"
                    :state="getValidationState(validationContext)"
                    placeholder="Enter Email"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" sm="8" lg="6">
              <validation-provider
                #default="validationContext"
                name="Phone number"
                rules="required"
              >
                <b-form-group
                  label="Phone number"
                  label-for="phone_number"
                  label-cols-sm="3"
                  class="required"
                >
                  <b-form-input
                    id="phone_number"
                    v-model="clientData.phone_number"
                    :state="getValidationState(validationContext)"
                    placeholder="Enter Phone number"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Gender"
                rules="required"
              >
                <b-form-group
                  label="Gender"
                  label-for="gender_id"
                  label-cols-sm="3"
                  label-cols="12"
                >
                  <div class="form-col-select">
                    <v-select
                      v-model="clientData.gender_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="genderOptions"
                      label="name"
                      :reduce="(option) => option.id"
                      :clearable="false"
                      placeholder="Select gender"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" sm="8" lg="6" v-if="clientData.id == 0">
              <validation-provider
                #default="validationContext"
                name="Referred Code"
                rules=""
              >
                <b-form-group
                  label="Referred Code"
                  label-for="referred_code"
                  label-cols-sm="3"
                  class=""
                >
                  <b-form-input
                    id="referred_code"
                    v-model="clientData.referred_code"
                    :state="getValidationState(validationContext)"
                    placeholder="Enter Referred Code"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" sm="8" lg="6">
              <b-form-group
                label="Status"
                label-for="is_active"
                label-cols-md="3"
                class=""
              >
                <!-- <b-form-input id="is_active"  v-model="clientData.is_active" /> -->
                <b-form-checkbox
                  :checked="clientData.is_active"
                  name="is_active"
                  switch
                  inline
                  v-model="clientData.is_active"
                  value="1"
                  style="margin-top: 5px"
                >
                  {{ clientData.is_active ? "Active" : "Inactive" }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col cols="12" md="12" v-if="clientData.id == 0">
              <h4 class="mb-0 color4">Password</h4>
              <hr />
            </b-col>
            <!-- Field: Password -->
            <b-col cols="12" md="6" v-if="clientData.id == 0">
              <validation-provider
                #default="validationContext"
                name="Password"
                rules="required|min:8"
              >
                <b-form-group
                  label-cols-sm="3"
                  label-cols="12"
                  class="required"
                  label="Password"
                  label-for="password"
                >
                  <b-form-input
                    id="password"
                    type="password"
                    :state="getValidationState(validationContext)"
                    v-model="clientData.password"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Field: Password Confirm -->
            <b-col cols="12" md="6" v-if="clientData.id == 0">
              <validation-provider
                #default="validationContext"
                name="Password Confirm"
                rules="required|min:8|confirmed:Password"
              >
                <b-form-group
                  label-cols-sm="3"
                  label-cols="12"
                  class="required"
                  label="Password Confirm"
                  label-for="c_password"
                >
                  <b-form-input
                    id="c_password"
                    type="password"
                    :state="getValidationState(validationContext)"
                    v-model="clientData.c_password"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <div class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-sm-2 mobile-w100"
              type="submit"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import textSlider from "@/components/slider/textSlider.vue";
import { ref, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";

export default {
  data() {
    return {
      required,
      alphaNum,
      email,
      flatDateConfigForBirth1: {
        altInput: true,
        altFormat: "Y-m-d",
        allowInput: true,
        dateFormat: "Y-m-d",
        maxDate: "today",
      },
    };
  },
  directives: {
    Ripple,
  },
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BOverlay,
    textSlider,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  watch: {
    clientData(newItem, oldItem) {
      return newItem.fatca_expiration_date
        ? (this.yearFilter = newItem.fatca_expiration_date)
        : (this.yearFilter = new Date().getFullYear());
    },
  },
  methods: {
    test() {
      console.log("click");
    },
    hide() {
      this.$router.replace("/clients");
    },
    onSubmit() {
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-client/addClient", this.clientData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.$router.push(`/clients`);
              if (this.clientData.id == 0) {
                // Return to client list
                //this.hide();
                //	sp1.hk.cmbintl.com
                this.clientData.id = response.data.id;
                this.redirect(response.data.id);
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
    genderOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const resetClientData = () => {
      props.clientData = JSON.parse(JSON.stringify({ id: 0 }));
    };

    const loading = ref(false);

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetClientData);

    const yearFilter = ref(new Date().getFullYear());

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      yearFilter,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
hr {
  width: calc(100% - 1.5rem);
  margin-top: 0px;
}

input::placeholder {
  color: lightgrey !important;
}

@media screen and (max-width: 767px) {
  .curr-btn {
    top: 51%;
  }
}
</style>
