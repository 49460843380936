<template>
  <component :is="clientData === undefined ? 'div' : 'b-card'">
    <b-alert variant="danger" :show="clientData === undefined">
      <h4 class="alert-heading">Error fetching client data</h4>
      <div class="alert-body">
        No client found with this client id. Check
        <b-link class="alert-link" :to="{ name: 'clients-list' }">
          Client List
        </b-link>
        for other clients.
      </div>
    </b-alert>
    <client-edit-tab-information
      :client-data="clientData"
      :gender-options="genderOptions"
      @refetch-data="refetchData"
      class="m-sm-2"
    />
  </component>
</template>

<script>
import { BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import clientStoreModule from "../clientStoreModule";
import ClientEditTabInformation from "./ClientEditTabInformation.vue";

export default {
  components: {
    ClientEditTabInformation,
    BCard,
    BAlert,
    BLink,
  },
  setup() {
    const clientData = ref({});

    const CLIENT_APP_STORE_MODULE_NAME = "app-client";
    const today = new Date();
    const year = today.getFullYear();

    const client = {
      id: 0,
      first_name: "",
      last_name: "",
      email: "",
      gender_id: 1,
      phone_number: "",
      referred_code: null,
      is_active: 1,
      password: "",
      c_password: "",
    };

    const demoClient = {
      id: 0,
      first_name: "",
      last_name: "",
      email: "",
      gender_id: 1,
      phone_number: "",
      referred_code: null,
      is_active: 1,
      password: "",
      c_password: "",
    };

    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
      store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      if (router.currentRoute.name == "clients-create") {
        console.log("client", client);
        clientData.value = client;
      } else {
        store
          .dispatch("app-client/fetchClient", {
            id: router.currentRoute.params.id,
          })
          .then((response) => {
            clientData.value = response.data.client;
          })
          .catch((error) => {
            console.log("error when fetching client", error);
            if (error.response.status === 404) {
              clientData.value = undefined;
            }
          });
      }
    };

    onMounted(() => {
      refetchData();
      refetchOption();
    });

    const genderOptions = ref([]);

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", {
          genders: true,
        })
        .then((response) => {
          genderOptions.value = response.data.genders;
        });
    };

    return {
      clientData,
      genderOptions,
      refetchData,
      refetchOption,
    };
  },
};
</script>
